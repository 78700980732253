/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { order_status, priority_status, WaterTask } from 'src/app/interfaces/water-task';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { UtilsService } from 'src/app/services/utils.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FilterComponent } from '../share/filter/filter.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

import { Subscription } from 'rxjs';
import { Itac } from 'src/app/interfaces/itac';
import { FormControl, FormGroup } from '@angular/forms';
import { MySqlService } from 'src/app/services/mysql.service';
import { MyLatLng } from '../../interfaces/lat-lng';
import { convertFromServer } from '../../interfaces/water-task';
import { Prediction } from '../../interfaces/place-predictions';
import { PlaceDetails } from '../../interfaces/place-details';
import { ElectronService } from 'ngx-electron';
import { Location } from '@angular/common';
import { GoogleLocation } from 'src/app/interfaces/google-location';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
    selector: 'app-task-location',
    templateUrl: './task-location.component.html',
    styleUrls: ['./task-location.component.scss'],
})
export class TaskLocationComponent implements OnInit, OnDestroy {
    @ViewChild(GoogleMap, { static: false }) map!: GoogleMap;
    @ViewChild(MapInfoWindow, { static: false }) infoWindow!: MapInfoWindow;

    inputSearchControl = new FormControl();
    placePredictions: Prediction[] = [];
    timerInputChanged: any;

    filterDialogRef!: MatDialogRef<FilterComponent, any>;

    markers: google.maps.Marker[] = [];
    markerOptionsMap: Map<string, google.maps.MarkerOptions> = new Map<
        string,
        google.maps.MarkerOptions
    >();
    loading: boolean = false;
    loadingMarkerInfo: boolean = false;
    zoom = 15;
    center!: google.maps.LatLngLiteral; // = new google.maps.LatLng(43.2633182, -2.9349041608216098);
    options = {
        origin: new google.maps.LatLng(43.2633182, -2.9349041608216098),
        types: [],
        componentRestrictions: { country: 'ES' },
        fields: ['place_id', 'geometry', 'name'],
        bounds: new google.maps.LatLngBounds(
            new google.maps.LatLng(19.43488390019791, -164.271592),
            new google.maps.LatLng(51.943852774877634, -79.896592)
        ),
        strictBounds: false,
    };
    mapOptions: google.maps.MapOptions = {
        mapTypeId: 'hybrid',
        disableDoubleClickZoom: true,
        tilt: 0,
    };

    markerTasks: WaterTask[] = [];

    dirSelected: string = '';

    task?: WaterTask;
    taskId?: string = '';

    taskSubscription$?: Subscription;
    
    googleLocation?: GoogleLocation;

    initialMs: number;

    constructor(
        private _apiService: ApiService,
        private _mySqlService: MySqlService,
        private _utilsService: UtilsService,
        public filterDialog: MatDialog,
        private spinner: NgxSpinnerService,
        private activatedRoute: ActivatedRoute,
        public _electronService: ElectronService,
        public location: Location
    ) {
        this.activatedRoute.params.subscribe((params) => { this.taskId = params['id']; });
        this.googleLocation = {};
        this.initialMs = Date.now();
    }

    /**
     * @brief Toggles 3D mode on the map by adjusting the tilt option.
     * @details This function sets the tilt option of the map to 45 when active is true (enabling 3D mode),
     * and sets it to 0 when active is false (disabling 3D mode).
     * @param active - A boolean indicating whether 3D mode should be active.
     */
    onMap3D(event: MatSlideToggleChange) {
        this.mapOptions = { ...this.mapOptions, tilt: event.checked ? 45: 0 };
    }

        
    async searchPlace(prediction: Prediction) {
        const place: PlaceDetails = await this._apiService.searchPlace(prediction.place_id);
        if (place) {
            const lat = place.result.geometry.location.lat; //lat drop
            const lng = place.result.geometry.location.lng; //lng drop
            this.center = {
                lat: lat, //position.coords.latitude,
                lng: lng, //position.coords.longitude,
            };
            setTimeout(async () => {
                try {
                    const result = await this._utilsService.openQuestionDialog(
                        'Confirmación',
                        '¿Desea situar marcador en esta localización?'
                    );
                    if (result) {
                        this.showLoading(true);
                        await this._updateMarkerPosition(lat, lng);
                        this.showLoading(false);
                    }
                } catch (err) {}
            }, 1000);
        }
    }

    asingnOptions(placePredictions: Prediction[]){
        if(!this.googleLocation!.text_options) this.googleLocation!.text_options = [];
        for (const prediction of placePredictions) {
            if(this.googleLocation!.text_options?.find((to)=>to.place_id == prediction.place_id)) continue;
            this.googleLocation!.text_options?.push({ value: prediction.description, place_id: prediction.place_id });
        }
    }

    async ngOnInit(): Promise<void> {
        this.showLoading(true);

        this.inputSearchControl.valueChanges.subscribe(async (value: any) => {
            clearTimeout(this.timerInputChanged);
            this.timerInputChanged = setTimeout(async () => {
                //Add a delay to input change
                this.placePredictions = await this._apiService.searchPrediction(value);
                this.asingnOptions(this.placePredictions);

            }, 1000);
        });

        this.taskSubscription$ = this._apiService
            .getTask(this.taskId!)
            .subscribe(async (doc: any) => {
                if (!doc) {
                    return;
                }
                const waterTask = convertFromServer(doc) as WaterTask;

                this.task = waterTask;

                const lat = this.task?.codigo_de_localizacion
                    ? this.task?.codigo_de_localizacion.lat
                    : this.task?.geolocalizacion?.lat;

                const lng = this.task?.codigo_de_localizacion
                    ? this.task?.codigo_de_localizacion.lng
                    : this.task?.geolocalizacion?.lng;

                const dir = this._utilsService.getDirOfTaskForMap(this.task);
                this.inputSearchControl.setValue(dir);

                this.googleLocation!.initialText = dir;

                if (!this.center) {
                    this.center = {
                        lat: lat || 43.2633182, //position.coords.latitude,
                        lng: lng || -2.9349041608216098, //position.coords.longitude,
                    };
                }
                this.addMarker(this.task);
                this.showLoading(false);
            });
    }

    ngOnDestroy(): void {
        this.taskSubscription$!.unsubscribe();
    }

    getDirOfTask(task: WaterTask) {
        return this._utilsService.getDirOfTask(task, false);
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('mapSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('mapSpinner');
        }
    }

    getTaskImage(task: WaterTask) {
        return this._utilsService.getTaskImage(task);
    }

    getMarkerOption(task: WaterTask) {
        let markerIconName: string = `assets/img/markers/${this.getCustomMarker(task)}.svg`;
        const markerIcon: google.maps.Icon = {
            url: markerIconName,
            scaledSize: new google.maps.Size(40, 40),
        };
        const markerOption: google.maps.MarkerOptions = {
            icon: markerIcon,
            // animation: google.maps.Animation.DROP,
            draggable: true,
        };
        return markerOption;
    }
    
    getCounterMarkerOption(task: WaterTask) {
        let markerIconName: string = `assets/img/markers/counter_location.svg`;
        if (task && task.pendent_location) markerIconName = 'assets/img/markers/pendent_location.png';
        const markerIcon: google.maps.Icon = {
            url: markerIconName,
            scaledSize: new google.maps.Size(40, 40),
        };
        const markerOption: google.maps.MarkerOptions = {
            icon: markerIcon,
            // animation: google.maps.Animation.DROP,
            draggable: true,
        };
        return markerOption;
    }

    addMarker(task: WaterTask) {
        this.markers = [];
        if (task.geolocalizacion) {
            const lat = task.geolocalizacion.lat;
            const lng = task.geolocalizacion.lng;
            const posCounter = new google.maps.LatLng(lat, lng);
            const markerCounter = new google.maps.Marker({
                position: posCounter,
                title: 'Casa', //This is the hover text of marker
                label: this._utilsService.getDirOfTask(task, true), //This is the text uppon the marker
                draggable: true,
            });
            this.markerOptionsMap.set('Casa', this.getMarkerOption(task));
            this.markers.push(markerCounter);
        }
        if (task.codigo_de_localizacion) {
            const lat = task.codigo_de_localizacion.lat;
            const lng = task.codigo_de_localizacion.lng;
            const posHome = new google.maps.LatLng(lat, lng);
            const markerHome = new google.maps.Marker({
                position: posHome,
                title: 'Contador', //This is the hover text of marker
                label: this._utilsService.getDirOfTask(task, true), //This is the text uppon the marker
                draggable: true,
            });
            this.markerOptionsMap.set('Contador', this.getCounterMarkerOption(task));
            this.markers.push(markerHome);
        }
    }

    onDrag(event: any, marker: google.maps.Marker) {
    }

    async onDragEnd(event: any, marker: google.maps.Marker) {
        this.showLoading(true);
        
        const lat = event?.latLng?.lat(); //lat drop
        const lng = event?.latLng?.lng(); //lng drop
        
        const latLng = this._utilsService.createLatLng(lat, lng);
        
        if(latLng) {
            if (marker.getTitle() == 'Casa') {
                this.task!.geolocalizacion = latLng;
            } else if (marker.getTitle() == 'Contador') {
                this.task!.codigo_de_localizacion = latLng;
                this.task!.url_geolocalizacion = this._utilsService.getGeolocationUrl(latLng);
            }
            
            const result = await this._apiService.updateTask(this.taskId!, this.task, true);
            
            if (result) {
                await this.saveGoogleLocation(latLng);
                
                this._utilsService.openSnackBar('Posición actualizada correctamente');
                if (this.task?.codigo_de_geolocalizacion) {
                    await this.updateTasksInPosition(
                        this.task.codigo_de_geolocalizacion,
                        this.task.codigo_de_localizacion,
                        this.task.geolocalizacion
                    );
                    await this.updateItacInPosition(this.task.codigo_de_geolocalizacion, latLng);
                }
            } else {
                this._utilsService.openSnackBar('Actualización de posición fallida', 'error');
            }
        }
        this.showLoading(false);
    }

    async updateTasksInPosition(
        emplacement_code: string,
        positionCounter?: MyLatLng,
        positionHome?: MyLatLng
    ) {
        const tasks = await this._apiService.getTasks([
            ['codigo_de_geolocalizacion', '==', emplacement_code],
        ]);
        if (tasks.length < 1) return;

        const ids = tasks.map((task) => task.id!);

        let data: any = {};
        if (positionCounter) {
            data['pendent_location'] = false;
            data['codigo_de_localizacion'] = positionCounter;
            data['url_geolocalizacion'] = this._utilsService.getGeolocationUrl(positionCounter);
        }
        if (positionHome) data['geolocalizacion'] = positionHome;

        const res = await this._apiService.updateTasks(ids, data, true);

        if (res) return;
        setTimeout(() => {
            this._utilsService.openSnackBar('Error actualizando de posición tareas', 'error');
        }, 3000);
    }

    async updateItacInPosition(emplacement_code: string, position: MyLatLng) {
        let errorUpdatingItac = false;

        let whereJson = [];
        //   const geoString = `%\\\"lat\\\":${lat},\\\"lng\\\":${lng}%`;
        // {"lat":43.26285723929354,"lng":-2.937125029885941,"geohash":"eztyj5v22"}
        let json_codigo_itac: any = {};
        json_codigo_itac['field'] = 'codigo_itac';
        json_codigo_itac['value'] = emplacement_code;
        json_codigo_itac['type'] = 'AND';
        whereJson.push(json_codigo_itac);

        const itacs = await this._mySqlService.getItacs(
            undefined,
            JSON.stringify(whereJson),
            undefined,
            undefined,
            undefined,
            false
        );
        if (itacs.length < 1) return;

        itacs.forEach(async (itac: Itac) => {
            itac.geolocalizacion = position;
            if (!(await this._apiService.updateItac(itac.id!.toString(), itac))) {
                errorUpdatingItac = true;
            }
        });
        if (errorUpdatingItac) {
            setTimeout(() => {
                this._utilsService.openSnackBar('Error actualizando de posición itac', 'error');
            }, 5000);
        }
    }

    getCustomMarker(task: WaterTask): string {
        // diaria_radio_hibernar_priority_marker
        let stringMarker = '';
        if (task.TIPORDEN && task.TIPORDEN == order_status.DAILY) {
            stringMarker += 'diaria_';
        }
        if (this._utilsService.isFieldValid(task.tipoRadio)) {
            stringMarker += 'radio_';
        }

        if (
            task.hibernacion &&
            task.end_hibernation_date != null &&
            task.end_hibernation_date.getTime() > new Date().getTime()
        ) {
            stringMarker += 'hibernar_';
        } else if (
            task.cita_pendiente &&
            task.fecha_hora_cita != null &&
            this._utilsService.checkIfSameDay(task.fecha_hora_cita, new Date())
        ) {
            stringMarker += 'cita_';
        } else {
            if (task.prioridad != null) {
                if (task.prioridad == priority_status.HIGH) {
                    stringMarker += 'alta_';
                } else if (task.prioridad == priority_status.MEDIUM) {
                    stringMarker += 'media_';
                } else {
                    stringMarker += 'baja_';
                }
            } else {
                stringMarker += 'baja_';
            }
        }
        stringMarker += 'priority_marker';
        return stringMarker;
    }

    compareMarkesPriority(firstMarker: string, secondMarker: string) {
        let firstPriority = 0,
            secondPriority = 0;
        if (firstMarker.includes('radio')) {
            firstPriority += 1;
        }
        if (firstMarker.includes('diaria')) {
            firstPriority += 10;
        }
        if (firstMarker.includes('hibernar')) {
            firstPriority += 100;
        }
        if (firstMarker.includes('baja')) {
            firstPriority += 200;
        }
        if (firstMarker.includes('media')) {
            firstPriority += 300;
        }
        if (firstMarker.includes('alta')) {
            firstPriority += 400;
        }
        if (firstMarker.includes('cita')) {
            firstPriority += 500;
        }

        if (secondMarker.includes('radio')) {
            secondPriority += 1;
        }
        if (secondMarker.includes('diaria')) {
            secondPriority += 10;
        }
        if (secondMarker.includes('hibernar')) {
            secondPriority += 100;
        }
        if (secondMarker.includes('baja')) {
            secondPriority += 200;
        }
        if (secondMarker.includes('media')) {
            secondPriority += 300;
        }
        if (secondMarker.includes('alta')) {
            secondPriority += 400;
        }
        if (secondMarker.includes('cita')) {
            secondPriority += 500;
        }
        return firstPriority < secondPriority;
    }

    click(event: google.maps.MapMouseEvent) {
        console.log('*********** click ***********');
        console.log(event);
        console.log('*********** click ***********');
    }

    async mapDblclick(event: google.maps.MapMouseEvent) {
        this.showLoading(true);

        const lat = event?.latLng?.lat() || 0; //lat drop
        const lng = event?.latLng?.lng() || 0; //lng drop
        await this._updateMarkerPosition(lat, lng);

        this.showLoading(false);
    }

    async _updateMarkerPosition(lat: number, lng: number) {
        const point: MyLatLng | null = this._utilsService.createLatLng(lat, lng);

        if(!point) return;

        if (this.markers.length > 0 && this.markers[0].getTitle() == 'Contador') {
            this.task!.geolocalizacion = point;
        } else {
            this.task!.codigo_de_localizacion = point;
            this.task!.url_geolocalizacion = this._utilsService.getGeolocationUrl(point);
        }

        const result = await this._apiService.updateTask(this.taskId!, this.task, true);

        if (result) {
            await this.saveGoogleLocation(point);

            if (this.task) this.addMarker(this.task);
            this._utilsService.openSnackBar('Posición actualizada correctamente');
            if (this.task?.codigo_de_geolocalizacion) {
                await this.updateTasksInPosition(
                    this.task.codigo_de_geolocalizacion,
                    this.task.codigo_de_localizacion,
                    this.task.geolocalizacion
                );
                await this.updateItacInPosition(this.task.codigo_de_geolocalizacion, point);
            }
        } else {
            this._utilsService.openSnackBar('Actualización de posición fallida', 'error');
        }
    }

    getTaskSubscriberTextLine(task: WaterTask) {
        let abonado = `ABONADO ${task.Numero_de_ABONADO}, ${task.NOMBRE_ABONADO}`;
        return abonado;
    }
    getTaskCounterTextLine(task: WaterTask) {
        let type = `${task.tipo_tarea}, CAL ${task.CALIBRE}mm, CT ${task.SERIE}`;
        return type;
    }

    async saveGoogleLocation(point: MyLatLng){
        this.googleLocation!.user_id = this._utilsService.getLoggedInUser()!.id;
        this.googleLocation!.task_id = parseInt(this.taskId!);
        this.googleLocation!.time_selecting_ms = Date.now() - this.initialMs;
        this.googleLocation!.finalText = this.inputSearchControl.value;
        this.googleLocation!.geolocation = point;
        this.googleLocation!.date = new Date();
        const id = await this._apiService.addGoogleLocation(this.googleLocation!);
    }
}
