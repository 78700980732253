/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {
    WaterTask,
    getDisplayColumns,
    getField,
    getFieldType,
    order_status,
    task_status,
    priority_status,
    getFieldName,
    getFilterDateTypes
} from 'src/app/interfaces/water-task';
import { MapInfoWindow } from '@angular/google-maps';
import { UtilsService } from 'src/app/services/utils.service';
import {
    faSignOutAlt,
    faBuilding,
    faFilter,
    faHome,
    faHouseUser,
    faUserCog,
    faAsterisk,
    faUsers,
    faBarcode,
    faMapMarkedAlt,
    faMap,
    faIdBadge,
    faCalendar,
    faPhoneAlt,
    faLowVision,
    faPen,
    faEraser,
    faSave,
    faChartPie,
    faMobile,
    faToolbox,
    faUserGroup,
    faTools,
    faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FilterComponent } from '../share/filter/filter.component';
import { FormControl, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { MiRutaUser } from '../../interfaces/mi-ruta-user';
import { ApiService } from 'src/app/services/api.service';
import { Subscription } from 'rxjs';
import { MySqlService } from '../../services/mysql.service';
import { Footprint } from '../../interfaces/footprint';

import { Sector } from '../../interfaces/sector';
import { MatDrawer } from '@angular/material/sidenav';
import { MyLatLng } from '../../interfaces/lat-lng';
import { IpcService } from 'src/app/services/ipc.service';
import { Prediction } from 'src/app/interfaces/place-predictions';
import { PlaceDetails } from 'src/app/interfaces/place-details';
import { getSideFieldType } from '../../interfaces/side';

export enum DrawerOption {
    SHOW_TASKS_CARDS = 1,
    SHOW_SECTORS = 2,
}
@Component({
    selector: 'app-google-maps',
    templateUrl: './google-maps.component.html',
    styleUrls: ['./google-maps.component.scss'],
})
export class GoogleMapsComponent implements OnInit, OnDestroy {
    @ViewChild('map', { static: false }) googlemaps!: google.maps.Map;
    @ViewChild(MapInfoWindow, { static: false }) infoWindow!: MapInfoWindow;
    @ViewChild('drawer') drawer?: MatDrawer;

    _enumDrawerOption = DrawerOption;
    drawerOption = DrawerOption.SHOW_TASKS_CARDS;
    formGroup: FormGroup = new FormGroup({
        map3D: new FormControl(),
        enableMarkers: new FormControl(),
        enableUserMarkers: new FormControl(),
        enableSectors: new FormControl(),
        enableMarkerDrag: new FormControl(),
    });
    filterDialogRef!: MatDialogRef<FilterComponent, any>;
    defaultSelect = [
        'MUNICIPIO',
        'CALLE',
        'NUME',
        'BIS',
        'PISO',
        'MANO',
        'telefono1',
        'telefono2',
        'NUMERO_CARNET_FIRMANTE',
        'end_hibernation_priority',
        'codigo_de_localizacion',
        'geolocalizacion',
        'codigo_de_geolocalizacion',
        'TIPORDEN',
        'tipoRadio',
        'hibernacion',
        'end_hibernation_date',
        'cita_pendiente',
        'fecha_hora_cita',
        'prioridad',
        'Numero_de_ABONADO',
        'NOMBRE_ABONADO',
        'tipo_tarea',
        'CALIBRE',
        'SERIE',
        'firma_cliente',
        'foto_antes_instalacion',
        'foto_despues_instalacion',
        'foto_incidencia_1',
        'foto_incidencia_2',
        'foto_incidencia_3',
        'foto_lectura',
        'foto_numero_serie',
        'pendent_location',
        'planning',
        'date_time_modified',
        // 'sectors',
        'id',
    ];

    initialSelect = [
        'MUNICIPIO',
        'CALLE',
        'NUME',
        'end_hibernation_priority',
        'codigo_de_localizacion',
        'geolocalizacion',
        'codigo_de_geolocalizacion',
        'TIPORDEN',
        'tipoRadio',
        'hibernacion',
        'end_hibernation_date',
        'cita_pendiente',
        'fecha_hora_cita',
        'prioridad',
        'pendent_location',
        'planning',
        // 'sectors',
        'id',
    ];

    taskCount = 0;

    filteredColumn?: string;
    range = new FormGroup({
        start: new FormControl(),
        end: new FormControl(),
    });
    showFilter: boolean = false;

    faSignOutAlt = faSignOutAlt;
    faBuilding = faBuilding;
    faHome = faHome;
    faCircleExclamation = faCircleExclamation;
    faHouseUser = faHouseUser;
    faUserCog = faUserCog;
    faUsers = faUsers;
    faBarcode = faBarcode;
    faMapMarkedAlt = faMapMarkedAlt;
    faMap = faMap;
    faIdBadge = faIdBadge;
    faUserGroup = faUserGroup;
    faAsterisk = faAsterisk;
    faCalendar = faCalendar;
    faFilter = faFilter;
    faPen = faPen;
    faChartPie = faChartPie;
    faEraser = faEraser;
    faSave = faSave;
    faLowVision = faLowVision;
    faPhoneAlt = faPhoneAlt;
    faMobile = faMobile;
    faToolbox = faToolbox;
    faTools = faTools;

    tasksCountThreshold = 2000;

    lastMarker: any;

    userMarkerIcon: google.maps.Icon = {
        url: 'assets/img/markers/user_marker.png',
        scaledSize: new google.maps.Size(30, 30),
        // size: new google.maps.Size(30, 30),
    };
    userMarkerOption: google.maps.MarkerOptions = {
        icon: this.userMarkerIcon,
        zIndex: 5,
        // animation: google.maps.Animation.DROP,
    };
    userMarkerOptionBouncing: google.maps.MarkerOptions = {
        icon: this.userMarkerIcon,
        animation: google.maps.Animation.BOUNCE,
    };

    footprintMarkerIcon: google.maps.Icon = {
        url: 'assets/img/markers/footprint.png',
        scaledSize: new google.maps.Size(30, 30),
        // size: new google.maps.Size(30, 30),
    };
    footprintMarkerOption: google.maps.MarkerOptions = {
        icon: this.footprintMarkerIcon,
        zIndex: 3,
        // animation: google.maps.Animation.DROP,
    };
    footprintMarkerOptionBouncing: google.maps.MarkerOptions = {
        icon: this.footprintMarkerIcon,
        animation: google.maps.Animation.BOUNCE,
    };
    startMarkerIcon: google.maps.Icon = {
        url: 'assets/img/markers/start.png',
        scaledSize: new google.maps.Size(30, 30),
        // size: new google.maps.Size(30, 30),
    };
    startMarkerOption: google.maps.MarkerOptions = {
        icon: this.startMarkerIcon,
        zIndex: 6,
        // animation: google.maps.Animation.DROP,
    };

    drawingMarkerIcon: google.maps.Icon = {
        url: 'assets/img/markers/point.png',
        scaledSize: new google.maps.Size(12, 12),
    };
    drawingMarkerOption: google.maps.MarkerOptions = {
        icon: this.drawingMarkerIcon,
        zIndex: 6,
    };

    startMarkerOptionBouncing: google.maps.MarkerOptions = {
        icon: this.startMarkerIcon,
        animation: google.maps.Animation.BOUNCE,
    };
    endMarkerIcon: google.maps.Icon = {
        url: 'assets/img/markers/end.png',
        scaledSize: new google.maps.Size(25, 35), 
        // size: new google.maps.Size(30, 30),
    };
    endMarkerOption: google.maps.MarkerOptions = {
        icon: this.endMarkerIcon,
        zIndex: 6,
        // animation: google.maps.Animation.DROP,
    };
    endMarkerOptionBouncing: google.maps.MarkerOptions = {
        icon: this.endMarkerIcon,
        animation: google.maps.Animation.BOUNCE,
    };
    markers: google.maps.Marker[] = [];
    usersMarkers: Set<google.maps.Marker> = new Set<google.maps.Marker>();
    footprintMarkers: Set<google.maps.Marker> = new Set<google.maps.Marker>();
    endPositionMarkers: Set<google.maps.Marker> = new Set<google.maps.Marker>();
    startPositionMarkers: Set<google.maps.Marker> = new Set<google.maps.Marker>();
    polylines: any[] = [];

    markerOptionsMap: Map<string, google.maps.MarkerOptions> = new Map<string, google.maps.MarkerOptions>();
    markersTaskCount: Map<string, number> = new Map<string, number>();

    loading: boolean = false;
    loadingMarkerInfo: boolean = false;
    zoom = 9;
    center!: google.maps.LatLngLiteral; // = new google.maps.LatLng(43.2633182, -2.9349041608216098);
    options: google.maps.MapOptions;

    displayedColumns: string[] = getDisplayColumns();
    markerTasks: WaterTask[] = []; //? tasks of marker selected

    dirSelected: string = '';

    max_distance: number = 0.1; //Km
    bilboCenter = {
        latitude: 43.2633182,
        longitude: -2.9349041608216098,
    };

    currentStatus?: number = -1;
    additionalStatus?: number = -1;
    currentWhereTasksSelected?: any;

    usersSubscription?: Subscription;
    footprintSubscription?: Subscription;
    waterTasks: WaterTask[] = [];
    sectors: Sector[] = [];

    timerCheckHibernationState: any;

    status_done_and_plus: number = task_status.IDLE;

    polylineOptions: any;
    lineSymbol = {
        path: 'M 0,-1 0,1',
        strokeOpacity: 1,
        scale: 4,
    };
    polylineOptionsDrawing: any;
    drewPolygon: any;
    polygons: any[] = [];

    drawingActive: boolean = false;
    painting: boolean = false;

    inputSearchControl = new FormControl();
    placePredictions: Prediction[] = [];
    timerInputChanged: any;

    lastSyncDate: Date;

    constructor(
        private _mySqlService: MySqlService,
        private _apiService: ApiService,
        public _utilsService: UtilsService,
        public filterDialog: MatDialog,
        private spinner: NgxSpinnerService,
        private router: Router,
        private route: ActivatedRoute,
        public _electronService: IpcService,
        public location: Location
    ) {
        this.setControls();
        this.getSessionVariables();
        //tilt option to set map on 3d or not recives values form 0-360
        this.options = { mapTypeId: 'roadmap', disableDoubleClickZoom: true, tilt: 0 }; 
        this.lastSyncDate = new Date();
    }

    /**
     * @brief Initializes the component and sets up necessary data and maps.
     * @details This function is part of the Angular lifecycle hook `ngOnInit`.
     * It fetches teams and users from the API, builds the map, and initializes session variables.
     * @return A Promise that resolves when the initialization is complete.
     */
    async ngOnInit(): Promise<void> {
        this.showLoading(true);
        await this._apiService.getTeams();
        this.center = { lat: this.bilboCenter.latitude, lng: this.bilboCenter.longitude };
        this.lastSyncDate = new Date();
        await this._apiService.getUsers(['administrador', 'operario']);
        await this.buildMap();
    }

    ngOnDestroy(): void {
        this.usersSubscription?.unsubscribe();
        this.footprintSubscription?.unsubscribe();
        clearInterval(this.timerCheckHibernationState);
    }

    /**
     * @brief Retrieves session variables related to task status and selection.
     * @details This function fetches task status and selection variables from the session storage.
     * These variables are used for maintaining the state of the application.
     */
    getSessionVariables(){
        try {
            this.currentStatus = parseInt(sessionStorage.getItem('currentTaskStatus')!);
        } catch (err) {}
        try {
            this.additionalStatus = parseInt(sessionStorage.getItem('additionalTaskStatus')!);
        } catch (err) {}
        try {
            const str = sessionStorage.getItem('currentWhereTasksSelected');
            if (str) this.currentWhereTasksSelected = JSON.parse(str);
        } catch (err) {}
    }

    /**
     * @brief Builds the map with locations and users.
     * @details This function is responsible for initializing the map with locations of users and teams.
     * It sets the zoom level, updates hibernated tasks, and loads the last map state.
     * @return A Promise that resolves when the map is built.
     */
    async buildMap(){
        await this.getLocations();
        await this.addUsers();
        this.setZoomLevel();
        if (this.markers) {
            this.timerCheckHibernationState = setInterval(async () => {
                await this.updateHibernatedTasks();
                this.checkHibernationState();
            }, 300000);
        }
        this.loadLastMapState();
    }

    async updateHibernatedTasks(){
        const savedFilter = JSON.stringify(this._utilsService.filterTasks);
        this.prepareFilterForNewModifications();
        const tasks = await this.getTasksInCurrentFilter();
        for(let task of tasks){
            const foundTask = this.waterTasks.find((t) => t.id == task.id);
            if(foundTask){
                const index = this.waterTasks.indexOf(foundTask);
                this.waterTasks[index] = task;
            }
        }
        this._utilsService.filterTasks = JSON.parse(savedFilter);
    }

    prepareFilterForNewModifications(){
        const values = this._utilsService.getDateRangeString([this.lastSyncDate]);
        this._utilsService.processFilter(
            this._utilsService.filterTasks!,
            values,
            'date_time_modified',
            'Date',
            this._mySqlService.tasksTableName,
            false,
        );
    }

    async checkHibernationState() {
        for (const markerOptionPair of this.markerOptionsMap) {
            let title = markerOptionPair[1].title as string;
            if (title.includes('__hibernated')) {
                title = title.replace('__hibernated', '').trim();
                const task = this.waterTasks.find((t) => t.id == parseInt(title));

                if (task) {
                    if(!task.end_hibernation_date) this.updateHibernatedMarker(task, markerOptionPair);
                    else if (task.end_hibernation_date && task.end_hibernation_date <= new Date()) {
                        this.updateHibernatedMarker(task, markerOptionPair);
                    }
                }
            }
        }
    }

    updateHibernatedMarker(task: WaterTask, markerOptionPair: any){
        const index = this.waterTasks.indexOf(task);
        const indexMarkerTasks = this.markerTasks.indexOf(task);
        task.hibernacion = false;
        task.prioridad = task.end_hibernation_priority || priority_status.LOW;
        if (index > 0) this.waterTasks[index] = task;
        if (indexMarkerTasks > 0) this.markerTasks[indexMarkerTasks] = task;
        this.markerOptionsMap.set(markerOptionPair[0], this.getMarkerOption(task));
    }

    async getTasksCountInCurrentFilter(): Promise<number> {
        let where_clause = this._utilsService.getTasksFilterWhereString();
        where_clause = this._utilsService.addStatusToWhereClause(where_clause);
        return await this._mySqlService.getTasksCount(where_clause);
    }

    async getTasksInCurrentFilter(): Promise<WaterTask[]> {
        const savedOffset = this._mySqlService.last_offset;
        let waterTasks: WaterTask[] = [];

        let where_clause = this._utilsService.getTasksFilterWhereString();
        where_clause = this._utilsService.addStatusToWhereClause(where_clause);

        const count = await this.getTasksCountInCurrentFilter();
        const order = this._utilsService.orderTasks;
        let order_clause = undefined;
        if (order.length > 0) order_clause = this._utilsService.getOrderClauseFromOrder(order);
        let limit: number = 1000;
        for (let offset = 0; offset < count; offset += limit) {
            const response = await this._mySqlService.getTasks(
                undefined,
                where_clause,
                order_clause,
                undefined,
                offset.toString(),
                limit.toString()
            );
            waterTasks = waterTasks.concat(response.waterTasks);
        }
        this._mySqlService.last_offset = savedOffset;
        return waterTasks;
    }

    setZoomLevel(){
        const zoom_distance = this._utilsService.log2(Math.round(40000 / (this.max_distance / 2)));
        let zoom_level = Math.floor(zoom_distance); 
        zoom_level = zoom_level < 2 ? 2 : zoom_level;
        this.zoom = zoom_level;
    }

    loadLastMapState(): void {
        let mapStateString = localStorage.getItem('mapState');
        if (mapStateString) {
            const mapState = JSON.parse(mapStateString);
            if (mapState) {
                this.lastMarker = mapState.lastMarker;
                this.markerTasks = mapState.markerTasks;
                this.drawerOption = mapState.drawerOption;
                this.dirSelected = mapState.dirSelected;
                this.center = mapState.center;
                this.zoom = mapState.zoom;
                this.drawer?.open();
            }
        }
    }

    setControls() {
        this.formGroup.controls['map3D'].setValue(false);
        this.formGroup.controls['enableMarkers'].setValue(true);
        this.formGroup.controls['enableUserMarkers'].setValue(true);
        this.formGroup.controls['enableSectors'].setValue(true);
        this.formGroup.controls['enableMarkerDrag'].setValue(false);

        this.formGroup.controls['map3D'].valueChanges.subscribe(async (value: boolean) => {
            this.onMap3D(value);
        });
        this.formGroup.controls['enableMarkerDrag'].valueChanges.subscribe(async (value: boolean) => {
            this.onEnableMarkerDragChange(value);
        });
        this.inputSearchControl.valueChanges.subscribe(async (value: any) => {
            clearTimeout(this.timerInputChanged);
            this.timerInputChanged = setTimeout(async () => {
                if(value) this.placePredictions = await this._apiService.searchPrediction(value);
            }, 1000);
        });
    }

    /**
     * @brief Toggles 3D mode on the map by adjusting the tilt option.
     * @details This function sets the tilt option of the map to 45 when active is true (enabling 3D mode),
     * and sets it to 0 when active is false (disabling 3D mode).
     * @param active - A boolean indicating whether 3D mode should be active.
     */
    onMap3D(active: boolean) {
        this.options = { ...this.options, tilt: active ? 45: 0 };
    }

    /**
     * @brief Handles the change in the marker drag state.
     * @details This function updates marker options based on the new marker drag state.
     * It iterates through marker options and adjusts them accordingly, considering hibernated tasks.
     * @param value - A boolean indicating whether marker drag is enabled or disabled.
     */
    onEnableMarkerDragChange(value: boolean) {
        for (const markerOptionPair of this.markerOptionsMap) {
            let title = markerOptionPair[1].title as string;
            title = title.replace('__hibernated', '').trim();
            const waterTask = this.waterTasks.find((waterTask) => waterTask.id == parseInt(title));
            if(waterTask) this.markerOptionsMap.set(markerOptionPair[0], this.getMarkerOption(waterTask, value));
        }
    }

    /**
     * @brief Searches for a place using a prediction and updates the map center.
     * @details This function fetches detailed information about a place using the provided place_id.
     * If the place information is available, it updates the map center to the location of the place.
     * @param prediction - The prediction object containing place_id.
     */
    async searchPlace(prediction: Prediction) {
        const place: PlaceDetails = await this._apiService.searchPlace(prediction.place_id);
        if (place) {
            const lat = place.result.geometry.location.lat; //lat drop
            const lng = place.result.geometry.location.lng; //lng drop
            this.center = {
                lat: lat, //position.coords.latitude,
                lng: lng, //position.coords.longitude,
            };
        }
    }

    /**
     * @brief Reloads the current route.
     * This function forces a reload of the current route, ensuring that the route is not reused and triggering a full page refresh.
     * @return {void}
     */
    reload(): void {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['./'], { relativeTo: this.route });
    }

    /**
     * @brief Displays an information dialog showing the count of displayed tasks on the map.
     * This function opens an information dialog that provides the count of currently displayed tasks on the map.
     * @return {Promise<void>} A Promise that resolves when the information dialog is displayed.
     */
    async showTasksCount(): Promise<void> {
        const c = this.taskCount;
        if(c > 0){
            const preText = `${c >1 ? 'muestran': 'muestra'}`;
            const text = `Actualmente se ${preText} ${c} ${c >1 ? 'tareas': 'tarea'} en el mapa`;
            await this._utilsService.openInformationDialog('Tareas mostradas', [text]);
        }
        else await this._utilsService.openInformationDialog('Sin tareas', ['No hay tareas para mostrar']);
    }

    /**
     * @brief Displays tasks in a table based on the filtered markers on the map.
     * @details This asynchronous function retrieves task IDs associated with visible markers on the map
     * using the getTasksInMarkers function. It then processes the filter using the retrieved IDs and
     * navigates back to the previous location.
     */
    async showInTable() {
        this._utilsService.clearTasksFilter();
        this._utilsService.processFilter(
            this._utilsService.filterTasks!,
            this.getTasksInMarkers(),
            'id',
            getFieldType('id'),
            this._mySqlService.tasksTableName
        )
        this.router.navigate(['/home']);
    }

    async showInSides() {
        this._utilsService.clearSidesFilter();
        this._utilsService.processFilter(
            this._utilsService.filterSides!,
            this.getTasksEmplacementCodesInMarkers(),
            'codigo_de_geolocalizacion',
            getSideFieldType('codigo_de_geolocalizacion'),
            this._mySqlService.sidesTableName
        )
        this.router.navigate(['/sides']);
    }

    getTasksEmplacementCodesInMarkers() {
        let codes = [];
        let arrayTask = [...this.waterTasks];
        for (const marker of this.markers) {
            if (marker.getVisible()) {
                for (let i = 0; i < arrayTask.length; i++) {
                    let waterTask = arrayTask[i];
                    if (
                        waterTask.codigo_de_localizacion?.lat == marker.getPosition()?.lat() &&
                        waterTask.codigo_de_localizacion?.lng == marker.getPosition()?.lng()
                    ) {
                        codes.push(waterTask.codigo_de_geolocalizacion);
                        arrayTask.splice(i, 1);
                        i--;
                    }
                }
            }
        }
        return codes;
    }

    /**
     * @brief Retrieves task IDs associated with visible markers on the map.
     * @details This function iterates through visible markers and matches them with water tasks.
     * It collects the IDs of tasks corresponding to visible markers and returns the array of IDs.
     * @return An array of task IDs associated with visible markers on the map.
     */
    getTasksInMarkers() {
        let ids = [];
        let arrayTask = [...this.waterTasks];
        for (const marker of this.markers) {
            if (marker.getVisible()) {
                for (let i = 0; i < arrayTask.length; i++) {
                    let waterTask = arrayTask[i];
                    if (
                        waterTask.codigo_de_localizacion?.lat == marker.getPosition()?.lat() &&
                        waterTask.codigo_de_localizacion?.lng == marker.getPosition()?.lng()
                    ) {
                        ids.push(waterTask.id);
                        arrayTask.splice(i, 1);
                        i--;
                    }
                }
            }
        }
        return ids;
    }

    /**
     * @brief Converts sector information into polygon options for display on the map.
     * @details This function takes a Sector object and extracts information to create polygon options.
     * The polygon options include the sector's ID, name, team, paths, and style.
     * @param s - The Sector object to extract information from.
     * @return Polygon options for displaying the sector on the map.
     */
    getPolygonOptionsFromSector(s: Sector){
        let paths = s.path.map((e) => new google.maps.LatLng(e.value.lat, e.value.lng));
        return {
            id: s.id,
            name: s.name,
            team: s.team,
            enabled: false,
            paths: paths,
            ...this.getPolyStyle()
        };
    }

    /**
     * @brief Loads sector information and displays sectors on the map.
     * @details This function fetches sector information, converts it into polygon options,
     * and adds the polygons to the map for display. It then opens the drawer to show the sectors.
     */
    async loadSectors() {
        const sectors: Sector[] = await this._apiService.getSectors();
        this.polygons = [];
        for (const sector of sectors) {
            let polygonOptions = this.getPolygonOptionsFromSector(sector);
            this.polygons.push(polygonOptions);
        }
        this.drawerOption = DrawerOption.SHOW_SECTORS;
        this.drawer?.open();
    }

    /**
     * @brief Generates a tooltip for a polygon on the map.
     * @details This function takes a polygon object as input and generates a tooltip string based on the
     * team information associated with the polygon. If the polygon is assigned to a team, it returns a
     * tooltip indicating the team assignment. Otherwise, it returns a tooltip stating that no team is assigned.
     * @param polygon - The polygon object for which to generate the tooltip.
     * @return A string representing the tooltip for the given polygon.
     */
    getPolygonTooltip(polygon: any): string {
        if(polygon.team){
            const teamName = this._utilsService.teamPipe(polygon.team);
            return `Asignado a equipo ${teamName}`;
        }
        else return `No se ha asignado equipo ${polygon.name}`;
    }

    /**
     * @brief Initiates the process of editing a sector based on user selection.
     * @details This asynchronous function opens a selector dialog to let the user choose between
     * renaming the sector's perimeter or changing the assigned team. It then calls the corresponding
     * function based on the user's choice.
     * @param polygon - The polygon object representing the sector to be edited.
     * @return A Promise that resolves when the editing process is complete.
     */
    async editSector(polygon: any): Promise<void> {
        try {
            const opt = await this._utilsService.openSelectorDialog(
                'Seleccione opción', ['Renombrar perímetro', 'Cambiar equipo']);
            if(opt == 'Renombrar perímetro') await this.renameSector(polygon);
            else if(opt == 'Cambiar equipo') await this.editSectorTeam(polygon);
        } catch (err) {}
    }

    /**
     * @brief Changes the team assignment for a sector.
     * @details This asynchronous function allows the user to select a new team for the specified sector.
     * It shows a loading spinner during the update process and displays a snack bar with the result.
     * @param polygon - The polygon object representing the sector to be edited.
     * @return A Promise that resolves when the team editing process is complete.
     */
    async editSectorTeam(polygon: any): Promise<void> {
        try {
            const teamId = await this._apiService.selectTeamId();
            if (teamId <= 0) return;
                this.showLoading(true);
                const result = await this._apiService.updateDocument('sector', polygon.id, {team: teamId});
                if (result) {
                    this._utilsService.openSnackBar(`Equipo de ${polygon.name} cambiado correctamente`);
                    polygon.team = teamId;
                } else
                    this._utilsService.openSnackBar(`Error cambiando equipo ${polygon.name}`, 'error');
                this.showLoading(false);
            
        } catch (err) {}
    }

    /**
     * @brief Renames a sector.
     * @details This asynchronous function allows the user to input a new name for the specified sector.
     * It shows a loading spinner during the update process and displays a snack bar with the result.
     * @param polygon - The polygon object representing the sector to be renamed.
     * @return A Promise that resolves when the sector renaming process is complete.
     */
    async renameSector(polygon: any): Promise<void> {
        try {
            const newName = await this._utilsService.openInputSelectorDialog(
                'Cambiar nombre del perímetro',
                polygon.name
            );
            if (newName) {
                this.showLoading(true);
                const result = await this._apiService.updateDocument('sector', polygon.id, {name: newName});
                if (result) {
                    this._utilsService.openSnackBar(`Perímetro ${polygon.name} renombrado correctamente`);
                    polygon.name = newName;
                } else
                    this._utilsService.openSnackBar(`Error cambiando perímetro ${polygon.name}`, 'error');
                this.showLoading(false);
            }
        } catch (err) {}
    }

    /**
     * @brief Deletes a sector based on user confirmation.
     * @details This asynchronous function prompts the user for confirmation before deleting the specified sector.
     * It shows a loading spinner during the delete process and displays a snack bar with the result.
     * @param polygon - The polygon object representing the sector to be deleted.
     * @param index - The index of the sector in the polygons array.
     * @return A Promise that resolves when the sector deletion process is complete.
     */
    async deleteSector(polygon: any, index: number): Promise<void> {
        try {
            const text = `Seguro desea eliminar el perímetro ${polygon.name}`;
            const res = await this._utilsService.openQuestionDialog('Confirmación', text);
            if (res) {
                this.showLoading(true);
                const result = await this._apiService.deleteDocument('sector', polygon.id);
                if (result) {
                    this._utilsService.openSnackBar(`Perímetro ${polygon.name} eliminando correctamente`);
                    this.polygons.splice(index, 1);
                } else this._utilsService.openSnackBar(`Error eliminando perímetro ${polygon.name}`, 'error');
                this.showLoading(false);
            }
        } catch (err) {}
    }

    /**
     * @brief Toggles the visibility of a polygon on the map.
     * @details This function toggles the visibility state of the specified polygon on the map.
     * If the polygon is enabled, it adjusts the map's bounds to fit the polygon.
     * It then updates the visibility of markers based on their positions relative to the enabled polygons,
     * and calculates the total task count within the visible markers.
     * @param polygon - The polygon object representing the sector to toggle.
     * @param map - The Google Maps map object.
     */
    togglePolygon(polygon: any, map: any) {
        polygon.enabled = !polygon.enabled;
        if (polygon.enabled) {
            let bounds = new google.maps.LatLngBounds();
            polygon.paths.forEach((latLng: any) => { bounds.extend(latLng); });
            this.googlemaps.fitBounds(bounds);
        }
        this.taskCount = 0;
        this.markers.forEach((marker: google.maps.Marker) => {
            marker.setVisible(false);
            this.polygons.forEach((polygon) => {
                this.isVisibleMarker(polygon, marker);
            });
        });
    }

    /**
     * Checks if a marker is visible within a given polygon.
     * @param polygon - The polygon object containing the paths.
     * @param marker - The marker object to check visibility for.
     */
    isVisibleMarker(polygon: any, marker: google.maps.Marker) {
        if (polygon.enabled) {
            let poly = new google.maps.Polygon({ paths: polygon.paths });
            poly.setPath(polygon.paths);
            const contains = this.containsMarker(polygon, marker);
            if (contains){
                this.taskCount += this.markersTaskCount.get(marker.getPosition()!.toString())!;
                marker.setVisible(true);
            } 
        }
    }

    /**
     * Checks if a given marker is contained within a polygon.
     * @param polygon The polygon object.
     * @param marker The marker object.
     * @returns A boolean indicating whether the marker is contained within the polygon.
     */
    containsMarker(polygon: any, marker: google.maps.Marker): boolean {
        let poly = new google.maps.Polygon({ paths: polygon.paths });
        poly.setPath(polygon.paths);
        const contains = google.maps.geometry.poly.containsLocation(
            marker.getPosition()!,
            poly
        );
        return contains;
    }

    /**
     * Checks if a given task is contained within a polygon.
     * @param polygon - The polygon object representing the area.
     * @param task - The task to check.
     * @returns A boolean indicating whether the task is contained within the polygon.
     */
    containsTask(polygon: any, task: WaterTask): boolean {
        let poly = new google.maps.Polygon({ paths: polygon.paths });
        poly.setPath(polygon.paths);
        const coors = this._utilsService.getRightCoordinates(task);
        if(coors){
            return google.maps.geometry.poly.containsLocation(
                new google.maps.LatLng(coors.lat, coors.lng),
                poly
            );
        }
        return false;
    }

    /**
     * Checks if a sector contains a given task.
     * @param sector - The sector to check.
     * @param task - The task to check if it is contained in the sector.
     * @returns A boolean indicating whether the sector contains the task.
     */
    sectorContainsTask(sector: Sector, task: WaterTask): boolean {
        let paths = sector.path.map((e) => new google.maps.LatLng(e.value.lat, e.value.lng));
        let poly = new google.maps.Polygon({ paths: paths });
        poly.setPath(paths);
        const coors = this._utilsService.getRightCoordinates(task);
        if(coors){
            return google.maps.geometry.poly.containsLocation(
                new google.maps.LatLng(coors.lat, coors.lng),
                poly
            );
        }
        return false;
    }

    /**
     * Checks if a given marker is contained within a sector.
     * @param sector - The sector to check.
     * @param marker - The marker to check.
     * @returns True if the marker is contained within the sector, false otherwise.
     */
    sectorContainsMarker(sector: Sector, marker: google.maps.Marker): boolean {
        let paths = sector.path.map((e) => new google.maps.LatLng(e.value.lat, e.value.lng));
        let poly = new google.maps.Polygon({ paths: paths });
        poly.setPath(paths);
        return google.maps.geometry.poly.containsLocation(
            marker.getPosition()!,
            poly
        );
    }
    
    /**
     * Saves the drawn zone.
     * 
     * @returns A promise that resolves to void.
     */
    async saveDrewZone(): Promise<void> {
        try {
            const res = await this._utilsService.openQuestionDialog(
                'Confirmación',
                '¿Desea salvar el perímetro dibujado?'
            );
            if (res) {
                const teamId = await this._apiService.selectTeamId();
                if(teamId <= 0) return;
                const zoneName = await this._utilsService.openInputSelectorDialog(
                    'Introduzca nombre del perímetro',
                    ''
                );
                await this.addPerimeterSector(zoneName, teamId);
            }
        } catch (err) {}
    }

    /**
     * Adds a perimeter sector to the map.
     * 
     * @param zoneName - The name of the zone.
     * @param teamId - The ID of the team.
     * @returns A Promise that resolves to void.
     */
    async addPerimeterSector(zoneName: string, teamId: number){
        if (!zoneName) return;
        this.showLoading(true);
        const company = localStorage.getItem('company');
        const manager = localStorage.getItem('manager');
        const sector: Sector = {
            name: zoneName,
            createdAt: new Date(),
            path: this.getDrewPath(),
            company: parseInt(company!),
            manager: parseInt(manager!),
            team: teamId,
        };
        await this.addSector(sector);
        this.toggleDrawing();
        this.showLoading(false);
    }

    /**
     * Adds a sector to the application.
     * 
     * @param sector - The sector to be added.
     * @returns A Promise that resolves to the ID of the added sector.
     */
    async addSector(sector: Sector){
        const sectorId = await this._apiService.addDocument('sector', sector);
        if (sectorId) {
            sector.id = sectorId;
            await this.updateTasksSector(sector);
            this._utilsService.openSnackBar('Perímetro salvado correctamente');
        }
        else this._utilsService.openSnackBar('Error salvando perímetro', 'error');
    }

    /**
     * Updates the tasks with the specified sector.
     * @param sector - The sector to update the tasks with.
     * @returns A Promise that resolves when the tasks have been updated.
     */
    async updateTasksSector(sector: Sector) {
        let tasksWithSectors: WaterTask[] = [];
        let tasksWithoutSectors: WaterTask[] = [];
        const ids = [];
        for(const task of this.waterTasks) {
            if(this.sectorContainsTask(sector, task)) {
                ids.push(task.id);
            }
        }
        const where = `[{"field":"id","value":[${ids.join(', ')}], "type": "AND"}]`;
        const waterTasks = await this._apiService.getTasks(undefined, undefined, where);
        for(const task of waterTasks) {
            if(task.sectors?.length) tasksWithSectors.push(task);
            else tasksWithoutSectors.push(task);
        }
        const userId = this._apiService.getLoggedUserId();
        if(tasksWithoutSectors.length) {
            const ids = tasksWithoutSectors.map((t) => t.id!);
            const data = { sectors: [sector], ultima_modificacion: userId }
            await this._apiService.updateTasks(ids, data);
        }
        if(tasksWithSectors.length) {
            for(const task of tasksWithSectors) {
                task.sectors?.push(sector);
                const data = { sectors: task.sectors, ultima_modificacion: userId }
                await this._apiService.updateTask(task.id!, data);
            }
        }
    }

    /**
     * Retrieves the drawn path from the polyline options.
     * @returns An array of objects representing the latitude and longitude values of the drawn path.
     */
    getDrewPath(){
        let path: any[] = [];
        for (let geopoint of this.polylineOptionsDrawing.path) {
            let latLang = this._utilsService.createLatLng(
                geopoint.lat(),
                geopoint.lng()
            );
            path.push({ value: latLang });
        }
        return path;
    }

    /**
     * Toggles the drawing mode of the Google Maps component.
     * When the drawing mode is active, the user can draw on the map.
     * When the drawing mode is inactive, the user cannot draw on the map.
     */
    toggleDrawing() {
        this.drawingActive = !this.drawingActive;
        if (this.drawingActive) {
            this.centerMap();
            this.setPencilInMap(true);
        } else {
            this.polylineOptionsDrawing = null;
            this.centerMap();
            this.setPencilInMap(false);
        }
    }

    mouseMove(event: google.maps.MapMouseEvent, drawer: any) {
        if (!this.painting) return;
        if (!this.drawingActive) return;
        if (!this.polylineOptionsDrawing) return;
        this.addEventPointToPolyline(event);
    }

    mapClick(event: google.maps.MapMouseEvent, drawer: any) {
        if (this.drawingActive) {
            if (this.polylineOptionsDrawing) this.toggleDrawing();
            else this.addEventPointToPolyline(event);
        }
        this.showFilter = false;
        localStorage.setItem('mapState', '');
        drawer.close();
    }

    mapDlclick(event: any, drawer: any) {
        this.closeDrawingPoligon();
    }

    setPolyline(coordinates: any){
        this.polylineOptionsDrawing = {
            path: coordinates,
            ...this.getPolyStyle()
        };
    }

    closeDrawingPoligon(){
        this.painting = false;
        if(this.polylineOptionsDrawing) {
            let coordinates: google.maps.LatLng[] = this.polylineOptionsDrawing.path;
            if(coordinates && coordinates.length){
                this.drewPolygon = {
                    id: 'drewPolygon',
                    enabled: false,
                    paths: coordinates,
                    ...this.getPolyStyle()
                };
                this.addPointToPolyline(coordinates[0].lat(), coordinates[0].lng());
            }
        }
    }

    getPolyStyle(){
        return {
            strokeColor: '#FFFFFF',
            strokeOpacity: 1.0,
            strokeWeight: 8,
            fillColor: '#368DCE',
            fillOpacity: 0.35,
            zIndex: 3,
        }
    }

    addEventPointToPolyline(event: any) {
        const lat = event?.latLng?.lat() || 0;
        const lng = event?.latLng?.lng() || 0;
        this.addPointToPolyline(lat, lng);
    }

    addPointToPolyline(lat: number, lng: number) {
        let coords: google.maps.LatLng[] = [];
        if(this.polylineOptionsDrawing) coords = this.polylineOptionsDrawing.path
        coords.push(new google.maps.LatLng(lat, lng));
        this.setPolyline(coords);
    }

    setPencilInMap(active: boolean){
        let cursor = active ? 'url(assets/img/markers/grease-pencil.png), auto':'default';
        this.options = {
            mapTypeId: this.markers.length < this.tasksCountThreshold ? 'hybrid' : 'roadmap',
            draggable: !active,
            zoomControl: !active,
            scrollwheel: !active,
            disableDoubleClickZoom: true,
            draggableCursor: cursor,
        };
        this.painting = active;
    }

    /**
     * Centers the map based on the current zoom level and center coordinates.
     */
    centerMap(){
        this.zoom = this.googlemaps.getZoom() || 9;
        this.center = {
            lat: this.googlemaps.getCenter()?.lat() || 0,
            lng: this.googlemaps.getCenter()?.lng() || 0,
        };
    }

    /**
     * Returns the color associated with the priority of a given task.
     * 
     * @param task - The WaterTask object for which to determine the priority color.
     * @returns The color associated with the priority of the task.
     */
    getTaskPriorityColor(task: WaterTask) {
        return this._utilsService.getTaskPriorityColor(task);
    }

    /**
     * Retrieves the latitude and longitude from the provided event object.
     * @param event - The event object containing the latitude and longitude information.
     * @returns The latitude and longitude as a `MyLatLng` object.
     */
    getLatLngFromEvent(event: any): MyLatLng | null {
        const lat = event?.latLng?.lat(); 
        const lng = event?.latLng?.lng(); 

        const latLng = this._utilsService.createLatLng(lat, lng);
        return latLng;
    }

    /**
     * Handles the event when a marker is dragged to a new position on the map.
     * @param event - The event object containing information about the drag event.
     * @param marker - The Google Maps marker object that was dragged.
     */
    async onDragEnd(event: any, marker: google.maps.Marker) {
        const latLng = this.getLatLngFromEvent(event);
        if(latLng) {
            let whereJson = this.getWhereJsonPosition(marker);
            const filter = this._utilsService.filterTasks;
            if (filter && filter.fields) {
                let whereJsonArray = JSON.parse(this._utilsService.getWhereClauseFromFilter(filter));
                for (const json of whereJsonArray) whereJson.push(json);
            }
            await this.updateTasks(JSON.stringify(whereJson), latLng, marker);
        }
    }

    /**
     * Updates tasks based on the provided criteria and location.
     * @param whereString - The criteria used to filter tasks.
     * @param latLng - The latitude and longitude of the location.
     * @param marker - The Google Maps marker associated with the location.
     */
    async updateTasks(whereString: string, latLng: MyLatLng, marker: google.maps.Marker) {
        this.showLoading(true);
        const tasks = await this.filterTask(whereString, this.defaultSelect);
        if (tasks && tasks.length > 0) {
            await this.updateTaskInLocation(tasks, latLng);
            this.updateMap(marker, latLng, tasks[0]);
        }
        this.showLoading(false);
    }

    /**
     * Updates the position of a marker on the map and updates the marker options map.
     * 
     * @param marker - The Google Maps marker object to update.
     * @param latLng - The new latitude and longitude coordinates for the marker.
     * @param task - The WaterTask associated with the marker.
     */
    updateMap(marker: google.maps.Marker, latLng: MyLatLng, task: WaterTask) {
        const previousPos = marker.getPosition();
        const pos = new google.maps.LatLng(latLng.lat, latLng.lng);
        marker.setPosition(pos);
        this.markerOptionsMap.delete(previousPos!.toString());
        this.markerOptionsMap.set(pos.toString(), this.getMarkerOption(task, true));
    }

    async updateTaskInLocation(tasks: WaterTask[], latLng: MyLatLng) {
        let ok: boolean = true;
        let ids: number[] = [];
        for (let task of tasks) ids = tasks.map(task => task.id!);
        const data = {
            pendent_location: false,
            geolocalizacion: latLng,
            codigo_de_localizacion: latLng,
            url_geolocalizacion: this._utilsService.getGeolocationUrl(latLng)
        }
        const result = await this._apiService.updateTasks(ids, data, false);
        if (result) this._utilsService.openSnackBar('Ubicación de tareas actualizadas correctamente');
        else this._utilsService.openSnackBar('Error actualizando tareas', 'error');
    }

    getWhereJsonPosition(marker: google.maps.Marker){
        this.lastMarker = marker;
        const lat = marker.getPosition()!.lat();
        const lng = marker.getPosition()!.lng();

        this.center = { lat: lat, lng: lng };

        let whereJson = [];
        const geoString = `%\\\"lat\\\":${lat},\\\"lng\\\":${lng}%`;
        // {"lat":43.26285723929354,"lng":-2.937125029885941,"geohash":"eztyj5v22"}
        let json_codigo_de_localizacion: any = {};
        json_codigo_de_localizacion['field'] = 'codigo_de_localizacion';
        json_codigo_de_localizacion['value'] = geoString;
        json_codigo_de_localizacion['type'] = 'AND';
        whereJson.push(json_codigo_de_localizacion);

        return whereJson;
    }

    async onClickedMarker(marker: google.maps.Marker, drawer: any) {
        this.drawerOption = DrawerOption.SHOW_TASKS_CARDS;
        drawer.open();
        this.infoWindow.open();

        let whereJson = this.getWhereJsonPosition(marker);

        const filter = this._utilsService.filterTasks;
        if (filter && filter.fields) {
            let whereJsonArray = JSON.parse(this._utilsService.getWhereClauseFromFilter(filter));
            for (const json of whereJsonArray) whereJson.push(json);
        }
        const str = JSON.stringify(whereJson);
        this.loadingMarkerInfo = true;
        const select = this.defaultSelect;
        const tasks = await this.filterTask(str, select);
        let ids: number[] = [];
        this.markerTasks = [];
        for (const task of tasks) {
            if (ids.includes(task.id!)) continue;
            ids.push(task.id!);
            this.markerTasks.push(task);
        }
        if (this.markerTasks.length <= 0) this.dirSelected = '';
        else this.dirSelected = this._utilsService.getDirOfTask(this.markerTasks[0], true);
        this.loadingMarkerInfo = false;
    }

    async onClickedUserMarker(marker: google.maps.Marker, drawer: any) {
        const lat = marker.getPosition()!.lat();
        const lng = marker.getPosition()!.lng();

        this.center = {
            lat: lat, //position.coords.latitude,
            lng: lng, //position.coords.longitude,
        };
        const userId = marker.getLabel();

        try {
            const res = await this._utilsService.openQuestionDialog(
                'Confirmación',
                '¿Desea mostrar trayectoria de fontanero?'
            );
            if (res) {
                const date = await this._utilsService.openDateSelectorDialog('Fecha a mostrar');
                const dayString = moment(date).format('YYYY-MM-DD'); // '2021-10-13'
                const footprints: Footprint[] = await this._apiService.getUserFootPrint(
                    +userId!,
                    dayString
                );
                if (footprints && footprints.length > 0) {
                    this.setMarkersFootprints(footprints);
                } else {
                    this._utilsService.openSnackBar(
                        `No hay trayectoria del dia ${moment(date).format('DD/MM/YYYY')}`,
                        'error'
                    );
                }
            }
        } catch (err) {}
    }
    async openSettings() {
        await this._utilsService.openFilterConfigurationDialog(
            this._mySqlService.tasksTableName,
            this._utilsService.filterTasks
        );
    }
    removeTrace() {
        this.footprintMarkers.clear();
        this.startPositionMarkers.clear();
        this.endPositionMarkers.clear();
        this.polylineOptions = {};
    }

    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this.spinner.show('mapSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this.spinner.hide('mapSpinner');
        }
    }

    getTaskImage(task: WaterTask) {
        return this._utilsService.getTaskImage(task);
    }

    async getLocations() {
        const filter = this._utilsService.filterTasks;
        let where_clause = '';
        if (filter && filter.fields) where_clause = this._utilsService.getWhereClauseFromFilter(filter);
        this.showLoading(true);
        let arrayTask = await this.filterTask(where_clause, this.initialSelect);
        this.setMarkers(arrayTask);
        this.showLoading(false);
    }

    async addUsers() {
        const users = await this._apiService.getUsers(['administrador', 'operario']);
        this.setMarkersUsers(users);
    }

    setMarkers(arrayTask: WaterTask[]) {
        this.markerOptionsMap.clear();
        this.markersTaskCount.clear();
        this.taskCount = 0;
        this.markers = [];
        let firstTask = true;
        arrayTask.forEach((task) => {
            const coordinates: MyLatLng | undefined = this._utilsService.getRightCoordinates(task);
            if (coordinates) {
                if (firstTask) {
                    this.center = {
                        lat: coordinates.lat, //position.coords.latitude,
                        lng: coordinates.lng, //position.coords.longitude,
                    };
                    firstTask = false;
                }
                this.waterTasks.push(task);
                this.addMarker(task);
            }
        });
        let { mapTypeId, ...otheOptions } = this.options;
        mapTypeId = this.markers.length < this.tasksCountThreshold ? 'hybrid' : 'roadmap',
        this.options = { mapTypeId, ...otheOptions };
    }

    setMarkersUsers(users: MiRutaUser[]) {
        this.usersMarkers.clear();
        users.forEach((user) => {
            if (user.geolocalizacion) {
                this.addUserMarker(user);
            }
        });
    }

    setMarkersFootprints(footprints: Footprint[]) {
        this.footprintMarkers.clear();
        let coordinates: any = [];
        coordinates.push(
            new google.maps.LatLng(footprints[0].geolocation!.lat, footprints[0].geolocation!.lng)
        );
        for (let i = 1; i < footprints.length - 1; i++) {
            if (footprints[i].geolocation) {
                this.addFootprintMarker(footprints[i]);
                coordinates.push(
                    new google.maps.LatLng(
                        footprints[i].geolocation!.lat,
                        footprints[i].geolocation!.lng
                    )
                );
            }
        }
        this.addStartPositionMarker(footprints[0]);
        this.addEndPositionMarker(footprints[footprints.length - 1]);
        coordinates.push(
            new google.maps.LatLng(
                footprints[footprints.length - 1].geolocation!.lat,
                footprints[footprints.length - 1].geolocation!.lng
            )
        );
        this.polylineOptions = {
            path: coordinates,
            strokeOpacity: 0,
            strokeColor: '#FFFFFF',
            zIndex: 3,
            icons: [
                {
                    icon: this.lineSymbol,
                    offset: '0',
                    repeat: '20px',
                },
            ],
            // geodesic: true,
        };
    }

    getMarkerOption(task: WaterTask, draggable?: boolean, prevZIndex?: number): google.maps.MarkerOptions {
        const markerIconName: string = `assets/img/markers/${this.getCustomMarker(task)}.png`;
        const markerIcon = { url: markerIconName, scaledSize: new google.maps.Size(32, 32) };
        let zIndex = prevZIndex? prevZIndex++:1;
        let animation = undefined;
        if (markerIconName.includes('cita')) { animation = google.maps.Animation.BOUNCE; zIndex++; }
        let title = task.id!.toString();
        if (task.hibernacion) title += '__hibernated';
        
        const drag = (draggable) ? true : false;
        const markerOption = { title: title, icon: markerIcon, animation: animation, zIndex: zIndex, draggable: drag };
        if (this.formGroup.controls['enableMarkerDrag'].value != drag) this.formGroup.controls['enableMarkerDrag'].setValue(drag);

        return markerOption;
    }

    addUserMarker(user: MiRutaUser) {
        let lat = user.geolocalizacion!.lat;
        let lng = user.geolocalizacion!.lng;

        let pos = new google.maps.LatLng(lat, lng);

        let userName = this._utilsService.userPipe(user.id);
        if (!userName) userName = user.username;
        
        const marker = new google.maps.Marker({ position: pos, label: user.id.toString(), title: userName });
        this.usersMarkers.add(marker);
    }

    addFootprintMarker(footprint: Footprint) {
        let lat = footprint.geolocation!.lat;
        let lng = footprint.geolocation!.lng;

        let pos = new google.maps.LatLng(lat, lng);

        let footprintName = moment(footprint.date!).format('HH:mm  DD/MM/YYYY');
        const marker = new google.maps.Marker({
            position: pos,
            label: footprint.id.toString(),
            title: footprintName, //this is what get displayed on map
        });
        this.footprintMarkers.add(marker);
    }

    addStartPositionMarker(footprint: Footprint) {
        let lat = footprint.geolocation!.lat;
        let lng = footprint.geolocation!.lng;

        let pos = new google.maps.LatLng(lat, lng);

        let footprintName = `${moment(footprint.date!).format(
            'HH:mm  DD/MM/YYYY'
        )} -> Inicio de trayecto`;
        const marker = new google.maps.Marker({
            position: pos,
            label: footprint.id.toString(),
            title: footprintName, //this is what get displayed on map
        });
        this.startPositionMarkers.add(marker);
    }
    
    addEndPositionMarker(footprint: Footprint) {
        let lat = footprint.geolocation!.lat;
        let lng = footprint.geolocation!.lng;

        let pos = new google.maps.LatLng(lat, lng);
        let footprintName = `${moment(footprint.date!).format(
            'HH:mm  DD/MM/YYYY'
        )} -> Fin de trayecto`;
        const marker = new google.maps.Marker({
            position: pos,
            label: footprint.id.toString(),
            title: footprintName, //this is what get displayed on map
        });
        this.endPositionMarkers.add(marker);
    }

    addMarker(task: WaterTask) {
        const coordinates: MyLatLng | undefined = this._utilsService.getRightCoordinates(task);

        let lat = coordinates!.lat;
        let lng = coordinates!.lng;

        const distance = this._utilsService.getDistanceInKmBetweenEarthCoordinates(
            this.center.lat,
            this.center.lng,
            lat,
            lng
        );
        this.max_distance = distance > this.max_distance ? distance : this.max_distance;

        let pos = new google.maps.LatLng(lat, lng);

        let markerDisplayText = this._utilsService.getDirOfTask(task, true)
        markerDisplayText += '  ' + this._utilsService.getHibernationText(task);

        const marker: any = new google.maps.Marker({
            position: pos,
            label: task.id!.toString(),
            title: markerDisplayText.trim(), //display on map
        });
        marker['codigo_de_geolocalizacion'] = task.codigo_de_geolocalizacion;
        if (this.markerOptionsMap.has(pos.toString())) {
            const prevMarkerName = (this.markerOptionsMap.get(pos.toString())?.icon as google.maps.Icon).url;
            this.markersTaskCount.set(pos.toString(), this.markersTaskCount.get(pos.toString()) || 1 + 1);
            if (this.compareMarkersPriority(prevMarkerName, this.getCustomMarker(task))) {
                const currentMarker = this.markerOptionsMap.get(pos.toString());
                this.markerOptionsMap.set(pos.toString(), this.getMarkerOption(task, undefined, currentMarker!.zIndex!));
            }
        } else {
            this.markersTaskCount.set(pos.toString(), 1);
            this.markerOptionsMap.set(pos.toString(), this.getMarkerOption(task));
            this.markers.push(marker);
        }
        this.taskCount++;
    }

    /**
     * Generates a custom marker string based on the properties of the given WaterTask.
     *
     * @param {WaterTask} task - The task object containing details for generating the marker.
     * @returns {string} - A string representing the custom marker.
     *
     * The marker string is constructed based on the following conditions:
     * - If the task has a `pendent_location`, the marker will be 'pendent_location' followed by the task's priority text.
     * - If the task's `TIPORDEN` is `DAILY`, 'diaria_' is added to the marker string.
     * - If the task's `tipoRadio` field is valid, 'radio_' is added to the marker string.
     * - The task's priority text is appended to the marker string.
     * - Finally, 'priority_marker' is appended to the marker string.
     */
    getCustomMarker(task: WaterTask): string {
        const priorityText = this._utilsService.getPriorityText(task);
        if (this._utilsService.isPendingCall(task)) return 'pendent_call' + '_' + priorityText;
        
        if (task && task.pendent_location) return 'pendent_location' + '_' + priorityText;
        
        let stringMarker = '';
        if (task.TIPORDEN === order_status.DAILY) stringMarker += 'diaria_';
        
        if (this._utilsService.isFieldValid(task.tipoRadio)) stringMarker += 'radio_';

        stringMarker += priorityText + '_';
        stringMarker += 'priority_marker';
        return stringMarker;
    }

    /**
     * @brief Compares the priority of two markers.
     * @details This function compares the priority of two markers based on their
     * characteristics. It assigns priority values to different marker types and
     * returns a boolean indicating whether the priority of the first marker is
     * less than the priority of the second marker.
     * @param firstMarker - The first marker string to compare.
     * @param secondMarker - The second marker string to compare.
     * @returns True if the priority of the first marker is less than the second, false otherwise.
     */
    compareMarkersPriority(firstMarker: string, secondMarker: string) {
        let firstPriority = 0, secondPriority = 0;
        if (firstMarker.includes('radio')) firstPriority += 1;
        if (firstMarker.includes('diaria')) firstPriority += 10;
        if (firstMarker.includes('hibernar')) firstPriority += 100;
        if (firstMarker.includes('baja')) firstPriority += 200;
        if (firstMarker.includes('media')) firstPriority += 300;
        if (firstMarker.includes('alta')) firstPriority += 400;
        if (firstMarker.includes('cita')) firstPriority += 500;

        if (secondMarker.includes('radio')) secondPriority += 1;
        if (secondMarker.includes('diaria')) secondPriority += 10;
        if (secondMarker.includes('hibernar')) secondPriority += 100;
        if (secondMarker.includes('baja')) secondPriority += 200;
        if (secondMarker.includes('media')) secondPriority += 300;
        if (secondMarker.includes('alta')) secondPriority += 400;
        if (secondMarker.includes('cita')) secondPriority += 500;
        return firstPriority < secondPriority;
    }

    /**
     * @brief Applies a filter to tasks based on the specified values and column.
     * @details This asynchronous function applies a filter to tasks based on the provided
     * values, column identifier, and additional filter options. It updates the WHERE clause,
     * shows a loading indicator, retrieves filtered tasks using `filterTask` method, sets markers
     * based on the filtered tasks, and hides the loading indicator.
     * @param values - The values to be used for filtering.
     * @param column - The identifier of the column for filtering.
     * @param not_empty - Flag indicating whether to include non-empty values in the filter.
     * @param empties_checked - Flag indicating whether empty values are checked in the filter.
     */
    async applyFilter(
        values: any,
        column: string,
        not_empty: boolean = false,
        empties_checked: boolean = false
    ) {
        const where_clause = this._utilsService.getWhereClauseFromFilter(
            this._utilsService.processFilter(
                this._utilsService.filterTasks!,
                values,
                column,
                getFieldType(column),
                this._mySqlService.tasksTableName,
                true,
                not_empty,
                empties_checked
            )
        );
        this.showLoading(true);
        this.waterTasks = [];
        let arrayTask = await this.filterTask(where_clause);
        this.setMarkers(arrayTask);
        this.showLoading(false);
    }

    /**
     * @brief Opens a filter dialog for a specified column and returns the result.
     * @details This asynchronous function opens a filter dialog for the specified column,
     * utilizing information such as the column name, column identifier, tasks table name,
     * and the current filter configuration. It returns the result of the filter dialog.
     * @param column_name - The name of the column for display purposes.
     * @param column - The identifier of the column for filtering.
     * @returns The result of the filter dialog.
     */
    async openFilterDialog(column_name: string, column: string){
        return await this._utilsService.openFilterDialog(
            column_name,
            column,
            this._mySqlService.tasksTableName,
            this._utilsService.filterTasks
        );
    }

    /**
     * @brief Processes the provided values as a filter and updates the filter configuration.
     * @details This function processes the provided values as a filter and updates the filter
     * configuration based on the column, field type, and tasks table name. It uses the
     * `_utilsService.processFilter` method for this purpose.
     * @param values - The values to be processed as a filter.
     * @param column - The identifier of the column for filtering.
     */
    processFilter(values: any, column: string){
        this._utilsService.filterTasks = this._utilsService.processFilter(
            this._utilsService.filterTasks!,
            values,
            column,
            getFieldType(column),
            this._mySqlService.tasksTableName
        );
    }

    /**
     * @brief Processes the order for sorting tasks and updates the order configuration.
     * @details This function processes the provided field and direction (dir) and updates
     * the order configuration for sorting tasks. It uses the `_utilsService.processOrder` method
     * for this purpose.
     * @param field - The field based on which tasks will be sorted.
     * @param dir - The direction of sorting (ASC or DESC).
     */
    processOrder(field: string, dir: string) {
        this._utilsService.processOrder(
            this._mySqlService.tasksTableName,
            this._utilsService.orderTasks,
            field,
            dir
        );
    }

    /**
     * @brief Applies filters related to address fields.
     * @details This asynchronous function prompts the user to filter by MUNICIPIO, CALLE, and NUME.
     * It also specifies the order of filtering and applies the filters accordingly.
     */
    async filterAddress() {
        let res = await this.openFilterDialog('MUNICIPIO', 'MUNICIPIO');
        if (res && res.data) {
            this.processFilter(res.data, res.column);
            res = await this.openFilterDialog('CALLE', 'CALLE');
            if (res && res.data) {
                this.processFilter(res.data, res.column);
                res = await this.openFilterDialog('NUME', 'NUME');
                if (res && res.data) {
                    // Order by MUNICIPIO, CALLE, NUME, BIS, PISO, MANO
                    this.processOrder('MANO','ASC');
                    this.processOrder('PISO','ASC');
                    this.processOrder('BIS','ASC');
                    this.processOrder('NUME','ASC');
                    this.processOrder('CALLE','ASC');
                    this.processOrder('MUNICIPIO','ASC');
                    this.applyFilter(res.data, res.column, res.not_empty, res.empties_checked);
                }
            }
        }
    }

    /**
     * @brief Applies filters related to cause fields.
     * @details This asynchronous function prompts the user to filter by ANOMALIA and CALIBRE.
     * It also specifies the order of filtering and applies the filters accordingly.
     */
    async filterCause() {
        let result = await this.openFilterDialog('ANOMALIA', 'ANOMALIA');
        if (result && result.data) {
            this.processFilter(result.data, result.column);
            result = await this.openFilterDialog('CALIBRE', 'CALIBRE');
            if (result && result.data) {
                this.processOrder('CALIBRE', 'ASC');
                this.processOrder('ANOMALIA', 'ASC');
                this.applyFilter(
                    result.data,
                    result.column,
                    result.not_empty,
                    result.empties_checked
                );
            }
        }
    }

    /**
     * @brief Applies filters based on date types.
     * @details This asynchronous function prompts the user to select a date type and then filters by the selected type.
     */
    async filterDateType() {
        const dateTypes: any = getFilterDateTypes();
        try {
            const option = await this._utilsService.openSelectorDialog('Seleccione tipo de fecha', [
                'Emisión',
                'Importación',
                'Cita',
                'Ejecución',
                'Cierre',
                'Información',
                'Modificación',
            ]);
            if (option) await this.filterBy(getFieldName(dateTypes[option]));
        } catch (err) {}
    }
    
    /**
     * @brief Applies a filter based on selected teams.
     * @details This asynchronous function prompts the user to select a team and then filters by the selected team.
     */
    async filterTeam() {
        const teams = await this._apiService.getTeams();
        const teamSelected = await this._utilsService.openSelectorDialog(
            'Seleccione equipo',
            teams.map((team) => team.equipo_operario)
        );
        const teamSelectedId = teams.find((team) => team.equipo_operario == teamSelected)?.id;
        if (teamSelectedId) {
            this.applyFilter([teamSelectedId], 'equipo');
        }
    }

    /**
     * @brief Applies a filter based on selected operators.
     * @details This asynchronous function prompts the user to select an operator and then filters by the selected operator.
     */
    async filterOperator() {
        const users = await this._apiService.getUsers(['operario']);
        const userSelected = await this._utilsService.openSelectorDialog(
            'Seleccione operario',
            users.map((user) => this._utilsService.userPipe(user.id))
        );
        const userSelectedId = users.find(
            (user) => this._utilsService.userPipe(user.id) == userSelected
        )?.id;
        if (userSelectedId) {
            this.applyFilter([userSelectedId], 'OPERARIO');
        }
    }

    /**
     * @brief Applies a filter based on the last operator modifier.
     * @details This asynchronous function prompts the user to select an operator, and then filters by the last operator modifier.
     */
    async filterLastOperatorModifier() {
        const users = await this._apiService.getUsers(['operario']);
        const userSelected = await this._utilsService.openSelectorDialog(
            'Seleccione operario',
            users.map((user) => this._utilsService.userPipe(user.id))
        );
        const userSelectedId = users.find(
            (user) => this._utilsService.userPipe(user.id) == userSelected
        )?.id;
        if (userSelectedId) {
            this.applyFilter([userSelectedId.toString()], 'last_modification_operator_uid');
        }
    }

    /**
     * @brief Applies a filter based on selected operators.
     * @details This asynchronous function prompts the user to select a user and then filters by the selected user as a modifier.
     */
    async filterModifier() {
        const users = await this._apiService.getUsers(['administrador', 'operario']);
        const userSelected = await this._utilsService.openSelectorDialog(
            'Seleccione usuario',
            users.map((user) => this._utilsService.userPipe(user.id))
        );
        const userSelectedId = users.find(
            (user) => this._utilsService.userPipe(user.id) == userSelected
        )?.id;
        if (userSelectedId) this.applyFilter([userSelectedId.toString()], 'ultima_modificacion');
    }

    /**
     * @brief Applies a filter based on selected services.
     * @details This asynchronous function prompts the user to select a service and then filters by the selected service.
     */
    async filterService() {
        try {
            const services = this._utilsService._services;
            const serviceSelected = await this._utilsService.openSelectorDialog(
                'Seleccione servicio',
                services
            );
            if (serviceSelected) {
                this.applyFilter([serviceSelected], 'last_service');
            }
        } catch (err) {}
    }

    /**
     * @brief Applies a filter based on selected suppliers.
     * @details This asynchronous function prompts the user to select a supplier and then filters by the selected supplier.
     */
    async filterSupplier() {
        try {
            const suplies = this._utilsService._suplies;
            const supplySelected = await this._utilsService.openSelectorDialog(
                'Seleccione suministro',
                suplies
            );
            if (supplySelected) {
                this.applyFilter([supplySelected], 'suministros');
            }
        } catch (err) {}
    }

    /**
     * @brief Applies custom filters based on the specified filter type.
     * @details This asynchronous function applies custom filters based on the provided filter type.
     * It calls specific filter functions for different types such as address, abonado, telefono, etc.
     * @param filterType - The type of custom filter to be applied.
     */
    async customFilterBy(filterType: string) {
        if (filterType === 'Dirección') await this.filterAddress();
        else if (filterType === 'Abonado') await this.applyCustomFilter('Numero_de_ABONADO');
        else if (filterType === 'TELEFONO 1') await this.applyCustomFilter('telefono1');
        else if (filterType === 'Sin revisar') await this.applyFilter([1], 'last_modification_android');
        else if (filterType === 'Incidencias') await this.applyFilter([1], 'incidence');
        else if (filterType === 'Titular') await this.applyCustomFilter('NOMBRE_ABONADO');
        else if (filterType === 'Serie') await this.applyCustomFilter('SERIE');
        else if (filterType === 'Equipo') await this.filterTeam();
        else if (filterType === 'Operario') await this.filterOperator();
        else if (filterType === 'last_modification_operator_uid') await this.filterLastOperatorModifier();
        else if (filterType === 'ultima_modificacion') await this.filterModifier();
        else if (filterType === 'Servicio') await this.filterService();
        else if (filterType === 'Suministros') await this.filterSupplier();
        else if (filterType === 'Sector P') await this.applyCustomFilter('zona');
        else if (filterType === 'Código de emplazamiento') await this.applyCustomFilter('codigo_de_geolocalizacion');
        else if (filterType === 'Fecha') await this.filterDateType();
        else if (filterType === 'Causa Origen') await this.filterCause();
        else if (filterType === 'Otro campo') await this.openFilter();
    }

    /**
     * @brief Applies a custom filter to the specified column.
     * @details This asynchronous function prompts the user to open a custom filter dialog for the specified column.
     * It then processes the order and applies the filter accordingly.
     * @param column - The column on which the custom filter is applied.
     */
    async applyCustomFilter(column: string) {
        let result = await this._utilsService.openFilterDialog(
            getFieldName(column),
            column,
            this._mySqlService.tasksTableName,
            this._utilsService.filterTasks
        );
        if (result && result.data) {
            this._utilsService.processOrder(
                this._mySqlService.tasksTableName,
                this._utilsService.orderTasks,
                column,
                'ASC'
            );
            this.applyFilter(
                result.data,
                result.column,
                result.not_empty,
                result.empties_checked
            );
        }
    }
    
    /**
     * @brief Handles the selection of a date range.
     * @details This asynchronous function is called when a date range is selected.
     * If a valid date range is provided, it converts the date range into a string
     * using the `getDateRangeString` function and applies the filter to the filtered column.
     * If the date range is invalid, it displays an error message using a snackbar.
     * @param dateRange - The selected date range.
     */
    async onDateSelected(dateRange: Date[], timeRange?: Date[]) {
        if (dateRange) {
            const values = this._utilsService.getDateRangeString(dateRange, timeRange);
            await this.applyFilter(values, this.filteredColumn!);
        } else {
            this._utilsService.openSnackBar('Rango fechas inválido', 'error');
        }
    }

    /**
     * @brief Initiates the process of filtering tasks based on date and time ranges.
     * @details This asynchronous function prompts the user to select a date range and,
     * optionally, a time range. It then calls the `onDateSelected` function to handle
     * the selected date and time ranges for filtering tasks.
     */
    async filterByDates() {
        try {
            const dates = await this._utilsService.openDateRangeSelectorDialog(
                'Seleccione rango de fechas'
            );
            try {
                const times = await this._utilsService.openTimeRangeSelectorDialog(
                    'Seleccione rango de horas'
                );
                this.onDateSelected(dates, times);
            } catch (err) {
                this.onDateSelected(dates);
            }
        } catch (err) {}
    }

    /**
     * @brief Filters tasks based on the specified column.
     * @details This asynchronous function prompts the user to filter tasks based on the specified column.
     * It handles date filtering separately and applies the filter accordingly.
     * @param column - The column on which the filter is applied.
     */
    async filterBy(column: string) {
        this.showFilter = false;
        this.filteredColumn = getField(column);
        if (getFieldType(this.filteredColumn) == 'Date') {
            await this.filterByDates();
        } else {
            const res = await this.openFilterDialog(column, this.filteredColumn);
            if (res && res.data){
                this.applyFilter(res.data, res.column, res.not_empty, res.empties_checked);
            }
        }
    }

    /**
     * @brief Filters tasks based on the specified criteria.
     * @details This asynchronous function filters tasks based on the provided WHERE condition,
     * SELECT fields, and DISTINCT criteria. It retrieves tasks in batches from the MySQL service
     * and concatenates them into an array until all tasks are retrieved.
     * @param where - The WHERE condition for filtering tasks.
     * @param select - The SELECT fields to be retrieved. If not provided, uses the default SELECT fields.
     * @param distinct - The DISTINCT criteria for retrieving unique values.
     * @returns An array of WaterTask objects that match the specified criteria.
     */
    async filterTask(where?: string, select?: string[], distinct?: string) {
        let whereJsonArray = JSON.parse(where || '[]');
        let where_clause = where;
        if (this.currentStatus != undefined && this.currentStatus >= 0) {
            if (this.additionalStatus != -1) {
                let additionalStatusJson: any = {};
                if (this.additionalStatus == 1) additionalStatusJson['field'] = 'absent';
                else if (this.additionalStatus == 2) additionalStatusJson['field'] = 'cita_pendiente';
                else if (this.additionalStatus == 3) additionalStatusJson['field'] = 'incidence';
                additionalStatusJson['value'] = 1;
                additionalStatusJson['type'] = 'AND';
                whereJsonArray.push(additionalStatusJson);
            }
            let currentStatusJson: any = {};
            currentStatusJson['field'] = 'status_tarea';
            currentStatusJson['value'] = this.currentStatus;
            currentStatusJson['type'] = 'AND';
            whereJsonArray.push(currentStatusJson);
            where_clause = JSON.stringify(whereJsonArray);
        }
        if (this.currentWhereTasksSelected && this.currentWhereTasksSelected.length > 0) {
            for (const json of this.currentWhereTasksSelected) whereJsonArray.push(json);
            where_clause = JSON.stringify(whereJsonArray);
        }
        return this.requestTasks(where_clause, select, distinct);
    }

    /**
     * Retrieves water tasks based on the provided criteria.
     * @param where_clause - The WHERE clause for filtering tasks.
     * @param select - The SELECT clause for specifying the fields to retrieve. If not provided, the default select fields will be used.
     * @param distinct - The DISTINCT clause for retrieving distinct values of a specific field.
     * @returns A promise that resolves to an array of WaterTask objects.
     */
    async requestTasks(where_clause?: string, select?: string[], distinct?: string): Promise<WaterTask[]>{
        let arrayTask: WaterTask[] = [];
        let amount = 0, offset = 0;
        do {
            let select_clause: any = { fields: select ?? this.defaultSelect };
            if (distinct) select_clause['distincts'] = [distinct];
            const { waterTasks, count } = await this._mySqlService.getTasks(
                JSON.stringify(select_clause),
                where_clause,
                undefined,
                undefined,
                offset.toString(),
                '4000'
            );
            arrayTask = arrayTask.concat(waterTasks);
            amount = count;
            offset += 4000;
        } while (amount > offset);
        return arrayTask;
    }

    /**
     * @brief Opens the filter dialog for selecting a column to filter.
     * @details This asynchronous function sets the `showFilter` flag to true,
     * prompts the user to select a column for filtering, and then calls the `filterBy` function.
     */
    async openFilter() {
        try {
            this.showFilter = true;
            const values = this.displayedColumns.slice(1);
            const column = await this._utilsService.openSelectorDialog('Columna a filtrar', values);
            if (column) this.filterBy(column);
        } catch (err) {}
    }

    /**
     * @brief Gets the direction of a task.
     * @details This function calls the `_utilsService.getDirOfTask` function
     * to get the direction of the specified water task.
     * @param task - The water task for which to get the direction.
     * @returns The direction of the task.
     */
    getDirOfTask(task: WaterTask) {
        return this._utilsService.getDirOfTask(task, false);
    }

    /**
     * @brief Gets the text line for the subscriber of a task.
     * @details This function calls the `_utilsService.getTaskSubscriberTextLine` function
     * to get the text line for the subscriber of the specified water task.
     * @param task - The water task for which to get the subscriber text line.
     * @returns The text line for the subscriber of the task.
     */
    getTaskSubscriberTextLine(task: WaterTask) {
        return this._utilsService.getTaskSubscriberTextLine(task);
    }

    /**
     * @brief Gets the text line for the counter of a task.
     * @details This function calls the `_utilsService.getTaskCounterTextLine` function
     * to get the text line for the counter of the specified water task.
     * @param task - The water task for which to get the counter text line.
     * @returns The text line for the counter of the task.
     */
    getTaskCounterTextLine(task: WaterTask) {
        return this._utilsService.getTaskCounterTextLine(task);
    }

    /**
     * @brief Checks if a task has a radius.
     * @details This function calls the `_utilsService.checkIfFieldIsValid` function
     * to check if the `tipoRadio` field of the specified water task is valid.
     * @param task - The water task for which to check the radius.
     * @returns True if the task has a radius, false otherwise.
     */
    hasRadius(task: WaterTask) {
        return this._utilsService.isFieldValid(task.tipoRadio);
    }

    /**
     * @brief Opens the task details page in a new window or tab.
     * @details If the application is an Electron app, it stores the current map state in local storage
     * and navigates to the task details page using Angular routing. Otherwise, it opens a new browser window.
     * @param task - The water task for which to open the details page.
     */
    openTask(task: WaterTask) {
        if (this._electronService.isElectronApp()) {
            let mapState = {
                task: task,
                drawerOption: this.drawerOption,
                lastMarker: this.lastMarker,
                markerTasks: this.markerTasks,
                dirSelected: this.dirSelected,
                center: this.center,
                zoom: this.zoom,
            };
            localStorage.setItem('mapState', JSON.stringify(mapState));
            this.router.navigate(['/task', task.id]);
        } else {
            const url = this.router.serializeUrl(this.router.createUrlTree(['/task', task.id]));
            window.open(url, '_blank');
        }
    }
}
